import { captureEvent } from '@sentry/browser'
import axios from 'axios'

export const getUserBasesAndTables = async ({ token }) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const applicationsResponse = await brApi.get('/applications/')
    const applicationsData = applicationsResponse.data
    const basesData = applicationsData.filter((app) => app.type === 'database')
    return basesData
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Bases and Tables.',
      level: 'warning',
      extra: { error },
    })
    return []
  }
}

export const getFieldOptionsByViewId = async ({
  token,
  viewId,
}: {
  token: string
  viewId: string | number
}) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const viewsResponse = await brApi.get(
      `/database/views/${viewId}/field-options/`,
    )
    const viewsData = viewsResponse.data
    return viewsData
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Field Options.',
      level: 'warning',
      extra: { error },
    })
    return []
  }
}

export const getViewsForTable = async ({ token, tableId }) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const viewsResponse = await brApi.get(`/database/views/table/${tableId}/`)
    const viewsData = viewsResponse.data
    return viewsData
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Views for a Table.',
      level: 'warning',
      extra: { error },
    })
    return []
  }
}

export const getFieldsForTable = async ({ token, tableId }) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const fieldsResponse = await brApi.get(`/database/fields/table/${tableId}/`)
    const fieldsData = fieldsResponse.data
    return fieldsData
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Fields for a Table.',
      level: 'warning',
      extra: { error },
    })
    return []
  }
}

export const getTableById = async ({ token, tableId }) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const tableResponse = await brApi.get(`/database/tables/${tableId}/`)
    const tableData = tableResponse.data
    return tableData
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching a Table.',
      level: 'warning',
      extra: { error },
    })
    return {}
  }
}

export const createRecord = async ({ token, recordData, tableId }) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })
  const response = await brApi.post(
    `/database/rows/table/${tableId}/`,
    recordData,
  )
  return response.data
}

export const deleteRecord = async ({ token, tableId, rowId }) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })
  const response = await brApi.delete(
    `/database/rows/table/${tableId}/${rowId}/`,
  )
  return response.data
}

export const updateRecord = async (params: {
  token: string
  recordId: number
  recordData: unknown
  tableId: number
}) => {
  const { token, recordId, recordData, tableId } = params

  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })
  try {
    const response = await brApi.patch(
      `/database/rows/table/${tableId}/${recordId}/`,
      recordData,
    )
    return response.data
  } catch (err) {
    const error = new Error(
      err?.message || 'Unknown error updating Baserow record',
    )
    captureEvent({
      message: 'HubDash: Unknown error updating Baserow record',
      level: 'warning',
      extra: { error },
    })
  }
}

export const uploadFile = async ({ token, file }) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const uploadResponse = await brApi.post('/user-files/upload-file/', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return uploadResponse.data
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred uploading a File.',
      level: 'warning',
      extra: { error },
    })
    return null
  }
}

export const getWorkspaceUsers = async ({ token, workspaceId }) => {
  if (!workspaceId) return []
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const workspaceUsersResponse = await brApi.get(
      `/workspaces/users/workspace/${workspaceId}/`,
    )
    return workspaceUsersResponse.data
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Workspace Users.',
      level: 'warning',
      extra: { error },
    })
    return []
  }
}

export const getWorkspacePermissions = async ({
  token,
  workspaceId,
}: {
  token: string
  workspaceId: number
}) => {
  if (!workspaceId) return []
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const workspacePermissionsResponse = await brApi.get(
      `/workspaces/${workspaceId}/permissions/`,
    )
    const rolePermissions = workspacePermissionsResponse.data.find(
      (data) => data.name === 'role',
    )?.permissions
    return {
      rowReadPermission: rolePermissions['database.table.read_row'].default,
      rowUpdatePermission: rolePermissions['database.table.update_row'].default,
      createCommentPermission:
        rolePermissions['database.table.create_comment'].default,
    }
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Workspace permissions.',
      level: 'warning',
      extra: { error },
    })
    return { error: error }
  }
}

export const enrichRecords = (params: {
  token: string
  records: { id: number; commentCount: number; notificationMode: string }[]
  tableFieldsData: {
    id: number
    name: string
    primary: boolean
    type: string
  }[]
  tableId: number
  workspaceId: number
}) => {
  const { token, records, tableFieldsData, tableId, workspaceId } = params

  return records.map((record) => {
    const enrichedRecord = {
      fields: [],
      id: record.id,
      commentCount: record.commentCount || 0,
      notificationMode: record.notificationMode || 'only',
      getCellValue: (fieldName) => {
        const matchingCell = enrichedRecord?.fields?.find(
          (field) => field?.name === fieldName,
        )

        if (!matchingCell) {
          return null
        }

        return matchingCell?.value
      },
      getCellValueAsString: (fieldName) => {
        const field = enrichedRecord.fields.find(
          (field) => field.name === fieldName,
        )
        const fieldType = field?.type
        if (!field || !fieldType) {
          return 'Field not found'
        }
        switch (fieldType) {
          case 'date':
          case 'created_on': {
            const dateValue = enrichedRecord.fields.find(
              (field) => field.name === fieldName,
            ).value
            return dateValue
              ? new Date(dateValue).toLocaleDateString('en-AU')
              : ''
          }

          case 'single_select': {
            return enrichedRecord.fields.find(
              (field) => field.name === fieldName,
            ).value?.value
          }
          case 'link_row': {
            const linkValue = enrichedRecord.fields.find(
              (field) => field.name === fieldName,
            ).value
            const linkValues = []
            for (const item of linkValue) {
              const itemValue = item?.value?.value || item?.value
              linkValues.push(itemValue)
            }
            return linkValues.join(', ')
          }
          case 'lookup': {
            const lookupValue = enrichedRecord.fields.find(
              (field) => field.name === fieldName,
            ).value
            return lookupValue.map((lookup) => lookup.value)
          }
          case 'multiple_collaborators': {
            const collaboratorValue = enrichedRecord.fields.find(
              (field) => field.name === fieldName,
            ).value
            return collaboratorValue.map((collaborator) => collaborator.name)
          }
          default: {
            return enrichedRecord.fields.find(
              (field) => field.name === fieldName,
            ).value
          }
        }
      },
      name: '',
      updateRecord: (recordData) => {
        return updateRecord({
          token: token,
          recordId: enrichedRecord.id,
          recordData: recordData,
          tableId: tableId,
        })
      },
      activityUpdates: [],
      uploadFile: (file) => {
        return uploadFile({
          token: token,
          file: file,
        })
      },
      getWorkspaceUsers: () => {
        return getWorkspaceUsers({
          token: token,
          workspaceId: workspaceId,
        })
      },
      workspaceId: workspaceId,
    }
    for (const [key, value] of Object.entries(record)) {
      if (!key.startsWith('field_')) continue
      const fieldId = key.split('_')[1]
      const field = tableFieldsData.find(
        (field) => field.id === parseInt(fieldId),
      )
      enrichedRecord.fields.push({
        id: field.id,
        name: field.name,
        value: value,
        type: field.type,
      })
    }
    enrichedRecord.name =
      enrichedRecord.getCellValueAsString(
        tableFieldsData.find((field) => field.primary).name,
      ) || ''
    return enrichedRecord
  })
}

export const getRecordsForViewByPage = async ({
  token,
  viewId,
  pageNumber,
}) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })
  try {
    const allRecords = []
    const recordsResponse = await brApi.get(
      `/database/views/grid/${viewId}/?size=500&page=${pageNumber}`,
    )
    const recordsData = recordsResponse.data
    allRecords.push(...recordsData.results)
    // Return all fetched rows as "results"
    return { ...recordsData, results: allRecords }
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Records for a Table.',
      level: 'warning',
      extra: { error },
    })
    return { results: [] }
  }
}

export const getRecordsForViewByTextSearch = async ({
  token,
  viewId,
  searchValue,
}) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })
  try {
    const allRecords = []
    const recordsResponse = await brApi.get(
      `/database/views/grid/${viewId}/?size=500&search=${searchValue}`,
    )
    const recordsData = recordsResponse.data

    allRecords.push(...recordsData.results)
    return { ...recordsData, results: allRecords }
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Records for a Table.',
      level: 'warning',
      extra: { error },
    })
    return []
  }
}

export const getRecordsForTableByPage = async ({
  token,
  tableId,
  pageNumber,
}) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const allRecords = []
    const recordsResponse = await brApi.get(
      `/database/rows/table/${tableId}/?user_field_names=true&size=500&page=${pageNumber}`,
    )
    const recordsData = recordsResponse.data
    allRecords.push(...recordsData.results)
    // Return all fetched rows as "results"
    return { ...recordsData, results: allRecords }
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Records for a Table.',
      level: 'warning',
      extra: { error },
    })
    return []
  }
}
export const getRecordsForTableByTextSearch = async ({
  token,
  tableId,
  searchValue,
}) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const allRecords = []
    const recordsResponse = await brApi.get(
      `/database/rows/table/${tableId}/?user_field_names=false&size=500&search=${searchValue}`,
    )
    const recordsData = recordsResponse.data
    allRecords.push(...recordsData.results)
    return { ...recordsData, results: allRecords }
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Records for a Table.',
      level: 'warning',
      extra: { error },
    })
    return []
  }
}

export const getRecordsForTable = async ({ token, tableId }) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const allRecords = []
    const recordsResponse = await brApi.get(
      `/database/rows/table/${tableId}/?user_field_names=true`,
    )
    const recordsData = recordsResponse.data

    allRecords.push(...recordsData.results)

    // if there is a recordsData.next, we need to fetch the next page of records
    if (recordsData.next) {
      let next = recordsData.next
      while (next) {
        const nextPageResponse = await brApi.get(next)
        const nextPageData = nextPageResponse.data
        allRecords.push(...nextPageData.results)
        next = nextPageData.next
      }
    }

    // Return all fetched rows as "results"
    return { ...recordsData, results: allRecords }
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Records for a Table.',
      level: 'warning',
      extra: { error },
    })
    return []
  }
}

export const getRecordById = async (params: {
  token: string
  workspaceId: number
  tableId: number
  recordId: number
}) => {
  const { token, workspaceId, tableId, recordId } = params

  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  try {
    const [recordDataResponse, tableFieldDataResponse, tableDataResponse] =
      await Promise.all([
        brApi.get(
          `/database/rows/table/${tableId}/${recordId}/?include=metadata`,
        ),
        brApi.get(`/database/fields/table/${tableId}/`),
        brApi.get(`/database/tables/${tableId}/`),
      ])

    const recordData = recordDataResponse.data
    const recordMetadata = recordData.metadata
    const tableFieldData = tableFieldDataResponse.data
    const tableData = tableDataResponse.data

    recordData.commentCount = recordMetadata?.row_comment_count
    recordData.notificationMode = recordMetadata?.row_comments_notification_mode

    const processedRecord = enrichRecords({
      token,
      records: [recordData],
      tableFieldsData: tableFieldData,
      tableId,
      workspaceId,
    })

    return {
      record: processedRecord[0],
      table: {
        ...tableData,
        id: tableId,
        fields: tableFieldData,
      },
    }
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Record by id.',
      level: 'warning',
      extra: { error },
    })
    return null
  }
}

/**
 * Note: Baserow API refers to Bases as "Applications" with type "database",
 * which is why are fetching from the /applications/ endpoint.
 * @param token - The user's Baserow token
 * @param applicationId (baseId) - The id of the base to fetch
 */
export const getBaseById = async ({ token, applicationId }) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })
  try {
    const applicationResponse = await brApi.get(
      `/applications/${applicationId}/`,
    )
    return applicationResponse.data
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Application by id.',
      level: 'warning',
      extra: { error },
    })
    return {}
  }
}
/**
 * Fetches a row by its id
 * @param token - The user's Baserow token
 * @param tableId - The id of the table the row belongs to
 * @param rowId - The id of the row to fetch
 */
export const getRowById = async ({ token, tableId, rowId }) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })
  try {
    const rowResponse = await brApi.get(
      `/database/rows/table/${tableId}/${rowId}/`,
    )
    return rowResponse.data
  } catch (error) {
    captureEvent({
      message: 'HubDash: An error occurred fetching Row by id.',
      level: 'warning',
      extra: { error },
    })
    return {}
  }
}
